import PoetryContainer from "./poetry-container";
import GenericWriteAReview from "../Reviews/write-a-review";
import GenericReview from "../Reviews/generic-review";
import "../../css/poetry/right_panel.css";
import NoPoetrySelected from "./no-poetry-selected";
const RightPanel = ({ poetry, poetries }) => {
  return poetry ? (
    <>
      <div className="right-panel-container">
        <div className="poetry-header">{poetry.title}</div>
        <PoetryContainer poem={poetry} />
        <div className="poetry-centralidea">
          <h3
            style={{
              marginBottom: "8px",
            }}
          >
            Central Idea
          </h3>
          {poetry.poetryDescription}
        </div>
        <div className="poetry-title">
          <p>Poet Name : {poetry.poetName}</p>
          <br />
          <p style={{
            fontSize : "18px"
          }}>{poetry.poetDescription}</p>
        </div>
        <GenericReview id={poetry.id} isBook={false} />
        <GenericWriteAReview id={poetry.id} isBook={false} />
      </div>
    </>
  ) : (
    <>
      <NoPoetrySelected key={"anc"} poetries={poetries} />
    </>
  );
};

export default RightPanel;
