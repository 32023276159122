import { fireStorage, firestore } from "../firebase";
import AWS from "aws-sdk";
export const postEBook = async ({ body, toaster }) => {
  try {
    if (body.writerPic) {
      body.writerPic = await uploadFileToFirebaseStorage({
        file: body.writerPic,
        path: "/writerpic",
        isDownlodUrl: true,
      });
    }
    if (body.bookCover) {
      body.bookCover = await uploadFileToFirebaseStorage({
        file: body.bookCover,
        path: `/bookCovers`,
        isDownlodUrl: true,
      });
    }
    if (body.book) {
      body.book = await uploadFileToAWSS3({
        path: `/books`,
        file: body.book,
        isDownlodUrl: true,
      });
    }
    body.createdAt = Date.now();
    body["isVerified"] = false;
    let collectionRef = await firestore.collection("books");
    let getDocId = collectionRef.doc().id;
    body.id = getDocId;
    await collectionRef.doc(getDocId).set(body);
    toaster.success("Book was uploaded!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  } catch (e) {
    toaster.error(e.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
};

export const uploadFileToFirebaseStorage = async ({
  file,
  path,
  isDownlodUrl,
}) => {
  try {
    // Upload file to Firebase Storage
    const storageRef = fireStorage.ref(path);
    const fileRef = storageRef.child(file.name);
    await fileRef.put(file);
    if (isDownlodUrl) {
      return await fileRef.getDownloadURL();
    } // Get the reference to the file in Storage
    return fileRef.fullPath;
  } catch (e) {
    throw Error("Files wasn't uploaded");
  }
};

export const uploadFileToAWSS3 = async ({ file, path, isDownlodUrl }) => {
  try {
    // Upload file to Firebase Storage
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_S3_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_S3_BUCKET_REGION,
    });

    const params = {
      Bucket: "epub-books-files",
      Key: file.name,
      Body: file,
      ACL: "public-read",
    };

    const data = await s3.upload(params).promise();
    console.log("File uploaded successfully", data.Location);
    return data.Location;
  } catch (e) {
    console.log(e);
    throw Error("Files wasn't uploaded");
  }
};
