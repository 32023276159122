const BookSectionSeperator = () => {
  return (
    <div
      className="seperate-line"
      style={{
        width: "60%",
        height: "1px",
        margin: "10px auto",
        background: "#347d39",
      }}
    ></div>
  );
};

export default BookSectionSeperator;
