import "../../css/poetry/no-poetry-selected.css";
import { useNavigate } from "react-router-dom";
import { PREFIX } from "./SlateEditor/utils/constants";

const NoPoetrySelected = ({ poetries }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="container">
        <header>
          <h1>Experience the Power of Poetry</h1>
          <p>
            Discover a world of captivating poetry from the world's most
            talented poets.
          </p>
          <div className="cta">Read Now</div>
        </header>

        {poetries ? (
          <section id="poems">
            <h2>Recent Poems</h2>
            <ul>
              {poetries &&
                poetries.map((poetry, index) => (
                  <li key={index} className="card">
                    <h3>{poetry.title}</h3>
                    <p>By {poetry.poetName}</p>
                    <div
                      onClick={() => {
                        navigate(PREFIX + `/poetry/${index}/${poetry.id}`);
                      }}
                      className="cta"
                    >
                      Read Now
                    </div>
                  </li>
                ))}
            </ul>
          </section>
        ) : (
          <></>
        )}

        <section id="about">
          <h2>About Us</h2>
          <p>
            We are a modern poetry platform dedicated to bringing you the best
            poems from the most talented poets around the world. Our mission is
            to make poetry accessible to everyone and inspire a new generation
            of poets and poetry lovers.
          </p>
        </section>

        <footer>
          <p>© 2023 Poetry Website</p>
        </footer>
      </div>
    </>
  );
};

export default NoPoetrySelected;
