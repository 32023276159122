import LeftPanelPoetryBox from "./left_panel_poetry_box";
import { useNavigate } from "react-router-dom";
import "../../css/poetry/left-panel.css";
import { useState } from "react";
import { PREFIX } from "./SlateEditor/utils/constants";
const LeftPanel = ({ poetries, selectedIndex }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen((prevVal) => !prevVal);
  };
  return (
    <>
      <div className={`left-panel-container ${open ? "open" : "close"}`}>
        <div className={`poetry-left-panel ${open ? "open" : "close"}`}>
          <img
            src={PREFIX + "/poetry.png"}
            style={{
              mixBlendMode: "color-dodge",
              marginBottom: "10px",
            }}
            width="150px"
            height="150px"
            alt=""
          />

          <p className="poetry-title">Poetry</p>
          {poetries &&
            poetries.map((poetry, index) => (
              <LeftPanelPoetryBox
                handleSelectedIndex={() =>
                  navigate(PREFIX + `/poetry/${index}/${poetry.id}`)
                }
                selectedIndex={selectedIndex}
                key={index}
                poetry={poetry}
              />
            ))}
        </div>
      </div>
      <div
        className={`floatingbtn ${open ? "open" : "close"}`}
        onClick={handleOpen}
      >
        <span className="material-symbols-outlined">
          {open ? "arrow_back" : "arrow_right_alt"}
        </span>{" "}
      </div>
    </>
  );
};

export default LeftPanel;
