import "../css/navbar.css";
import { useLocation, useNavigate } from "react-router-dom";
import Music from "./Books/music/music_button";
import { useEffect, useRef, useState } from "react";
import { PREFIX } from "./Poetry/SlateEditor/utils/constants";
import MobileDropdown from "./mobile-dropdown";

const Navbar = () => {
  const navigate = useNavigate();
  const paths = useLocation();
  const navLinksRef = useRef(null);
  const [isToggle, setToggle] = useState(false);

  const navbarLinks = () => {
    let navlinks = document.querySelector(".nav-links");

    // }

    window.addEventListener("resize", function () {
      console.log(this.screen.width);
      if (this.screen.width >= 768) {
        setToggle(false);
      }
    });
  };

  const scrollNavObserver = () => {
    try {
      document.addEventListener("scroll", function () {
        let sideNav = document.querySelectorAll(".sidebar-links li");
        let navBar = document.querySelectorAll(".nav .link");
        if (navBar[1] && navBar[2] && navBar[3] && navBar[4]) {
          if (window.pageYOffset > 380 && window.pageYOffset < 1150) {
            navBar[1].classList.add("navCurr");
            navBar[2].classList.remove("navCurr");
          } else if (window.pageYOffset > 1150 && window.pageYOffset < 3030) {
            navBar[1].classList.remove("navCurr");
            navBar[3].classList.remove("navCurr");
            navBar[2].classList.add("navCurr");
          } else if (window.pageYOffset > 3030 && window.pageYOffset < 4030) {
            navBar[2].classList.remove("navCurr");
            navBar[4].classList.remove("navCurr");
            navBar[3].classList.add("navCurr");
          } else if (window.pageYOffset > 4030) {
            navBar[3].classList.remove("navCurr");
            navBar[4].classList.add("navCurr");
          } else {
            navBar[1].classList.remove("navCurr");
          }
        }

        if (sideNav[0] && sideNav[1] && sideNav[2] && sideNav[3]) {
          if (window.innerWidth > 1024 && window.pageYOffset < 1200) {
            sideNav[0].classList.remove("ocurr");
          } else if (
            window.innerWidth > 1024 &&
            window.pageYOffset > 1280 &&
            window.pageYOffset < 1640
          ) {
            sideNav[0].classList.add("occur");
            sideNav[1].classList.remove("occur");
          } else if (
            window.innerWidth > 1024 &&
            window.innerWidth > 1024 &&
            window.pageYOffset > 1770 &&
            window.pageYOffset < 2080
          ) {
            sideNav[0].classList.remove("occur");
            sideNav[2].classList.remove("occur");
            sideNav[1].classList.add("occur");
          } else if (
            window.innerWidth > 1024 &&
            window.pageYOffset > 2090 &&
            window.pageYOffset < 2550
          ) {
            sideNav[1].classList.remove("occur");
            sideNav[3].classList.remove("occur");
            sideNav[2].classList.add("occur");
          } else if (
            window.innerWidth > 1024 &&
            window.pageYOffset > 2550 &&
            window.pageYOffset < 3120
          ) {
            sideNav[2].classList.remove("occur");
            sideNav[3].classList.add("occur");
          } else {
            sideNav[3].classList.remove("occur");
          }

          if (window.innerWidth <= 1024 && window.pageYOffset < 1200) {
            sideNav[0].classList.remove("occur");
          } else if (
            window.innerWidth <= 1024 &&
            window.pageYOffset > 1280 &&
            window.pageYOffset < 2180
          ) {
            sideNav[0].classList.add("occur");
            sideNav[1].classList.remove("occur");
          } else if (
            window.innerWidth <= 1024 &&
            window.pageYOffset > 2180 &&
            window.pageYOffset < 2460
          ) {
            sideNav[0].classList.remove("occur");
            sideNav[2].classList.remove("occur");
            sideNav[1].classList.add("occur");
          } else if (
            window.innerWidth <= 1024 &&
            window.pageYOffset > 2460 &&
            window.pageYOffset < 2930
          ) {
            sideNav[1].classList.remove("occur");
            sideNav[3].classList.remove("occur");
            sideNav[2].classList.add("occur");
          } else if (
            window.innerWidth <= 1024 &&
            window.pageYOffset > 2930 &&
            window.pageYOffset < 3490
          ) {
            sideNav[2].classList.remove("occur");
            sideNav[3].classList.add("occur");
          }
        }
      });
    } catch (error) {
      console.error("Error in scrollNavObserver:", error);
      // You can handle the error here, for example, by logging it or showing a message to the user
    }
  };

  useEffect(() => {
    navbarLinks();
    scrollNavObserver();
  }, []);

  const getPathStatus = (linkPath) => {
    const path = paths.pathname;
    console.log(path);
    console.log(linkPath);
    if (path !== PREFIX) {
      navigate(PREFIX);
      if (
        !path.includes(PREFIX + "/books") &&
        !path.includes(PREFIX + "/read-book") &&
        !path.includes(PREFIX + "/poetry") &&
        !path.includes(PREFIX + "/book-details")
      ) {
        setTimeout(() => {
          const el = document.querySelector(linkPath);
          if (el) {
            el.scrollIntoView();
          }
        }, 300);
      }
    } else {
      const el = document.querySelector(linkPath);
      if (el) {
        el.scrollIntoView();
      }
    }
  };

  return (
    <>
      <div id="nav" className="nav">
        <div style={{
          "cursor" : "pointer"
        }} onClick={() => navigate("/")} className="name">
          Shelendra
        </div>
        <div className="links">
          <div className="link">
            <span onClick={() => getPathStatus("#intro")}>Home</span>
          </div>
          <div className="link">
            <span onClick={() => getPathStatus("#about")}>About</span>
          </div>
          <div className="link">
            <span onClick={() => getPathStatus("#resume")}>Resume</span>
          </div>
          <div className="link">
            <span onClick={() => getPathStatus("#projects")}>Project</span>
          </div>
          <div className="link">
            <span onClick={() => getPathStatus("#contact")}>Contact</span>
          </div>
        </div>
        <div className="links">
          <div className="link">
            <span onClick={() => navigate(PREFIX + "/books")}>Books</span>
          </div>
          <div className="link">
            <span onClick={() => navigate(PREFIX + "/poetry")}>Poetry</span>
          </div>
        </div>
        <MobileDropdown getPathStatus={getPathStatus} />
      </div>

      <div
        className="musicplayer"
        style={{
          position: "fixed",
          left: "5px",
          zIndex: "99999999",
        }}
      >
        <Music />
      </div>
    </>
  );
};

export default Navbar;
