import { useEffect } from "react";
import "../css/intro.css";
import { PREFIX } from "./Poetry/SlateEditor/utils/constants";

const Intro = () => {
  let isDeleting = false;
  let text = "";
  let names = ["Coding", "Reading", "Flutter"];
  let idx = 0;

  useEffect(() => {
    const scriptParticle = document.createElement("script");
    scriptParticle.src = PREFIX + "/particles.js";
    scriptParticle.async = false;
    document.body.appendChild(scriptParticle);
    const scriptApp = document.createElement("script");
    scriptApp.src = PREFIX + "/app.js";
    scriptApp.async = false;
    document.body.appendChild(scriptApp);
    return () => {
      document.body.removeChild(scriptParticle);
      document.body.removeChild(scriptApp);
      // document.body.removeChild(scriptCustom);
    };
  }, []);

  function typeWords() {
    let changingText = document.querySelector(".typewriter");
    if (changingText) {
      let word = names[idx];

      if (isDeleting && text.length == 0) {
        idx = (idx + 1) % names.length;
        word = names[idx];
        isDeleting = false;
      }

      if (text.length == word.length) {
        isDeleting = true;
      }

      text = isDeleting
        ? word.substring(0, text.length - 1)
        : word.substring(0, text.length + 1);
      changingText.innerHTML = text;
      setTimeout(typeWords, text.length == word.length ? 1500 : 150);
    }
  }
  useEffect(() => {
    typeWords();
  }, []);

  return (
    <div id="intro" className="intro">
      <div id="particles-js"></div>
      <script src="particles.js" async={false}></script>
      <script src="app.js" async={false}></script>
      <div className="content">
        <div className="p1">Hey! I Am</div>
        <div className="p2">Shelendra</div>
        <div className="p3">
          I Like <span className="typewriter"></span>
        </div>
      </div>
      <div className="trans-button">
        <span className="material-icons">expand_more</span>
      </div>
    </div>
  );
};

export default Intro;
