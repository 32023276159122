// components/EpubViewer.js
import React, { useEffect, useRef, useState } from "react";
import ePub from "epubjs"; // Specify the version
import { Slider } from "@mui/material";
import ReadBookSidebar from "./ReadBook/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faExpand,
} from "@fortawesome/free-solid-svg-icons";
import { PREFIX } from "../Poetry/SlateEditor/utils/constants";
import "../../css/books/epub_viewer.css";

const EpubViewer = ({ epubUrl, onChapterChange }) => {
  const [book, setBook] = useState(null);
  const viewRef = useRef(null);
  const just = "center";
  const [loading, setLoading] = useState(false);
  const [length, setLength] = useState(0);
  const [pages, setPages] = useState([]);
  const mainTainLocalStorage = ({
    color,
    textAlign,
    font,
    background,
    fontSize,
    lineHeight,
  }) => {
    localStorage.setItem(
      "epubState",
      JSON.stringify({
        color,
        textAlign,
        font,
        background,
        fontSize,
        lineHeight,
      })
    );
  };
  const getLocalStorageItem = () => {
    return JSON.parse(localStorage.getItem("epubState"));
  };
  useEffect(() => {
    let cleanupFunc = () => {};
    const loadBook = async () => {
      setLoading(true);
      try {
        const loadedBook = ePub(epubUrl);

        //   setBook(loadedBook);
        const rendition = loadedBook.renderTo("viewer", {
          width: "100%",
          height: "100%",
          allowScriptedContent: true,
        });

        rendition.display();

        setBook(rendition);
        getAllPages(rendition);
        rendition.on("relocated", (location) => {
          onChapterChange(location.start.index);
          changeEpubConfig({ color: "#ffffff" }, true);
        });
        cleanupFunc = () => {
          rendition.off("relocated", () => {});
          rendition.destroy();
        };
        rendition.hooks.content.register((content, view) => {
          content.content.style.color = "#fff";
          content.content.style.textAlign = just;
        });
      } catch (e) {}
    };

    if (!book) {
      loadBook();
    }

    return cleanupFunc;
  }, []);
  // Helper function to extract information about all pages
  const getAllPages = (rendition) => {
    rendition.book.ready.then(async (book) => {
      let locations = await rendition.book.locations.generate();
      setLength(locations.length);
      setPages(locations);
      setLoading(false);
    });

    // Combine information from manifest and spine
  };
  const showChapter = (index) => {
    book.display(pages[index]);
  };
  const nextChapter = () => {
    if (book) {
      book.next();
    }
  };

  const prevChapter = () => {
    if (book) {
      book.prev();
    }
  };
  const changeEpubConfig = (
    { color, textAlign, background, fontSize, lineHeight },
    isFromCallback = false
  ) => {
    let colorVal = color;
    let textAlignVal = textAlign;
    let backgroundVal = background;
    let fontSizeVal = fontSize;
    let lineHeightVal = lineHeight;
    if (isFromCallback) {
      let localStorageItems = getLocalStorageItem();
      if (localStorageItems) {
        colorVal = localStorageItems.color;
        textAlignVal = localStorageItems.textAlign;
        backgroundVal = localStorageItems.background;
        fontSizeVal = localStorageItems.fontSize;
        lineHeightVal = localStorage.lineHeight;
      }
    }
    const iframe = document.querySelector(".epub-view iframe");
    const iframeBody = iframe.contentDocument.body;
    if (iframeBody) {
      iframeBody.style.color = colorVal;
      iframeBody.style.textAlign = textAlignVal;
      iframeBody.style.background = backgroundVal;
      iframeBody.style.fontSize = fontSizeVal;
      iframeBody.style.lineHeight = lineHeightVal;
      if (!isFromCallback) {
        mainTainLocalStorage({
          color: colorVal,
          textAlign,
          background,
          fontSize,
          lineHeight,
        });
      }
    }
  };
  return (
    <>
      {loading ? (
        <>
          <div
            style={{
              height: "100vh",
              position: "absolute",
              width: "100vw",
              zIndex: "999999999",
              background: "#2F333A",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              opacity: "0.5",
            }}
          >
            <img src={PREFIX + "/books-load.gif"} alt="" />
          </div>
        </>
      ) : (
        <></>
      )}
      <>
        {" "}
        <div ref={viewRef} style={{ position: "relative" }}>
          <div
            id="viewer"
            style={{
              width: "100%",
              height: "85vh",
              background: "#222",
              // marginBottom: "20px",
            }}
          />
          <div
            style={{
              width: "90%",
              margin: "auto",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <button
              onClick={prevChapter}
              style={{
                background: "var(--github-green-color)",
                border: "none",
                cursor: "pointer",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                fontSize: "20px",
                display: "flex",
                marginRight: "10px",
                padding: "8px",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <Slider
              aria-label="Temperature"
              defaultValue={30}
              valueLabelDisplay="auto"
              getAriaValueText={(e) => e + 1}
              onChange={(e, val) => showChapter(val)}
              min={0}
              max={length}
              style={{ flexGrow: 1, margin: "0 10px" }}
            />
            <button
              onClick={nextChapter}
              style={{
                background: "var(--github-green-color)",
                border: "none",
                cursor: "pointer",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                padding: "8px",
                fontSize: "20px",
                display: "flex",
                justifyContent: "center",
                marginLeft: "10px",
                alignItems: "center",
                color: "white",
              }}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>

          <button
            onClick={() => {
              //toggle full screen
              if (!viewRef.current.fullscreenElement) {
                if (viewRef.current.requestFullscreen) {
                  viewRef.current.requestFullscreen();
                } else if (viewRef.current.mozRequestFullScreen) {
                  // Firefox
                  viewRef.current.mozRequestFullScreen();
                } else if (viewRef.current.webkitRequestFullscreen) {
                  // Chrome, Safari and Opera
                  viewRef.current.webkitRequestFullscreen();
                } else if (viewRef.current.msRequestFullscreen) {
                  // IE/Edge
                  viewRef.current.msRequestFullscreen();
                }
              } else {
                if (viewRef.current.exitFullscreen) {
                  viewRef.current.exitFullscreen();
                } else if (viewRef.current.mozCancelFullScreen) {
                  // Firefox
                  viewRef.current.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                  // Chrome, Safari and Opera
                  viewRef.current.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                  // IE/Edge
                  viewRef.current.msExitFullscreen();
                }
              }
            }}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "green",
              padding: "8px",
              borderRadius: "50%",
              color: "white",
              border: "none",
              height: "30px",
              zIndex: "99999",
              width: "30px",
              cursor: "pointer",
            }}
          >
            <FontAwesomeIcon icon={faExpand} />{" "}
            {/* Use the FontAwesomeIcon component */}
          </button>
        </div>
        <ReadBookSidebar changeEpubConfig={changeEpubConfig} />
      </>
    </>
  );
};

export default EpubViewer;
