import React, { useRef, useState } from "react";
import "./ColorPicker.css";
import { colors } from "./defaultColors.js";
import { addMarkData, activeMark } from "../../utils/SlateUtilityFunctions.js";
import { Transforms } from "slate";
import usePopup from "../../utils/usePopup";
import { ReactEditor } from "slate-react";

const ColorPicker = ({ format, editor, icon, initialColor }) => {
  const [selection, setSelection] = useState();
  
  const colorPickerRef = useRef(null);
  const [showOptions, setShowOptions] = usePopup(colorPickerRef);

  const changeColor = (e) => {
    const clickedColor = e.target.getAttribute("data-value");
    selection && Transforms.select(editor, selection);

    addMarkData(editor, { format, value: clickedColor });
    ReactEditor.focus(editor);
    Transforms.move(editor, {
      distance: 1,
    });
    setShowOptions(false);
  };
  const toggleOption = () => {
    setSelection(editor.selection);
    setShowOptions((prev) => !prev);
  };
 
  return (
    <div className="color-picker popup-wrapper" ref={colorPickerRef}>
      {/* {initialColor} */}
      <button
        style={{
          color: showOptions
            ? "black"
            : activeMark(editor, format, initialColor),
          opacity: "1",
        }}
        className={showOptions ? "clicked" : ""}
        onClick={toggleOption}
      >
        <span className="material-symbols-outlined">{icon}</span>
      </button>
      {showOptions && (
        <div className="popup">
          <div className="color-options">
            {colors.map((color, index) => {
              return (
                <div
                  key={index}
                  data-value={color}
                  onClick={changeColor}
                  className="option"
                  style={{ background: color }}
                ></div>
              );
            })}
          </div>
          {/* <p style={{ textAlign: "center", opacity: "0.7", width: "100%" }}>
            OR
          </p>
          <form onSubmit={handleFormSubmit}>
            <div
              className="hexPreview"
              style={{ background: validHex ? hexValue : "#000000" }}
            ></div>
            <input
              type="text"
              placeholder="#000000"
              value={hexValue}
              onChange={handleHexChange}
              style={{
                border:
                  validHex === false ? "1px solid red" : "1px solid lightgray",
              }}
            />
            <button style={{ color: validHex ? "green" : "" }} type={"submit"}>
              <MdCheck size={20} />
            </button>
          </form> */}
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
