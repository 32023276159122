import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Rating } from "@mui/material";
import { firestore } from "../../firebase";
import "../../css/Reviews/generic-review.css";
import { PREFIX } from "../Poetry/SlateEditor/utils/constants";

const GenericReview = ({ isBook, avgReview }) => {
  const [reviews, setReviews] = useState([]);
  const params = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const fetchReviews = async () => {
      let ref;
      if (isBook) {
        ref = firestore
          .collection("books")
          .doc(params.id)
          .collection("reviews");
      } else {
        ref = firestore
          .collection("poetry")
          .doc(params.docid)
          .collection("reviews");
      }

      ref.onSnapshot((snapshot) => {
        let revArr = snapshot.docs.map((review) => review.data());
        revArr.sort((a, b) => b.createdAt - a.createdAt);
        setReviews(revArr);
        // calculating avg review
        if (isBook) {
          let sum = 0;
          revArr.forEach((review) => {
            sum += parseInt(`${review.rating}`);
          });
          console.log(sum);
          avgReview(sum / (revArr.length ));
        }
        setLoading(false);
      });
    };

    fetchReviews();
  }, [params.id, isBook]);

  return (
    <>
      <p className="review-title">Reviews</p>
      <div className="reviews-container">
        <div className="review-image-container">
          <img
            alt=""
            style={{
              width: "100%",
              height: "300px",
            }}
            src={PREFIX + "/review_image.png"}
          ></img>
          <div className="vertical-line"></div>
        </div>
        <div className="review-list-container">
          {loading ? (
            <>Loading....</>
          ) : !reviews.length ? (
            <p className="no-reviews-message">No Reviews Found</p>
          ) : (
            reviews.map((review, index) => (
              <div key={index} className="review">
                <p className="review-text">
                  <sup>
                    <img alt="" src={PREFIX + "/opening_quote.png"}></img>
                  </sup>
                  {review.review}
                  <sub>
                    <img alt="" src={PREFIX + "/closing_quote.png"}></img>
                  </sub>
                </p>
                <div className="review-user-info">
                  <div className="user-info">
                    <img
                      alt=""
                      className="user-avatar"
                      height="50px"
                      width="50px"
                      src={PREFIX + "/user.jpg"}
                    ></img>
                    <p className="user-name">{review.name}</p>
                  </div>
                  <div className="review-rating">
                    <Rating
                      value={parseInt(`${review.rating ?? 0}`)}
                      disabled
                    />
                  </div>
                  <div className="review-created-at">
                    <p>
                      Created At :{" "}
                      {new Date(review.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default GenericReview;
