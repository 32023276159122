import {
  Box,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  Flex,
  Text,
  Center,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { PREFIX } from "./Poetry/SlateEditor/utils/constants";

const MobileDropdown = ({ getPathStatus }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  return (
    <Box
      className="burger"
      pos="relative"
      zIndex={9999}
      display={{ base: "block", tb: "none" }}
    >
      <Box cursor="pointer" onClick={isOpen ? onClose : onOpen}>
        <img src="harmburg.svg" />
      </Box>

      <Drawer onClose={onClose} isOpen={isOpen} size={"full"}>
        <DrawerOverlay bg="rgba(35, 39, 45, 0.6)" filter="blur(2.5px)" />
        <DrawerContent height="340px" bg="var(--navbar-color)">
          <Flex
            justifyContent="space-between"
            padding="24px"
            alignItems="center"
          >
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/");
                onClose();
              }}
              className="name"
            >
              Shelendra
            </div>
          </Flex>

          <DrawerBody maxWidth="356px" width="100%" margin="auto">
            <Box width="100%">
              <div
                onClick={() => {
                  getPathStatus("#intro");
                  onClose();
                }}
                className="nav-links"
              >
                <span>Home</span>
              </div>
              <div
                onClick={() => {
                  getPathStatus("#about");
                  onClose();
                }}
                className="nav-links"
              >
                <span>About</span>
              </div>
              <div
                onClick={() => {
                  getPathStatus("#education");
                  onClose();
                }}
                className="nav-links"
              >
                <span>Resume</span>
              </div>
              <div
                onClick={() => {
                  getPathStatus("#projects");
                  onClose();
                }}
                className="nav-links"
              >
                <span>Project</span>
              </div>
              <div
                onClick={() => {
                  getPathStatus("#contact");
                  onClose();
                }}
                className="nav-links"
              >
                <span>Contact</span>
              </div>
              <div
                onClick={() => {
                  navigate(PREFIX + "/books");
                  onClose();
                }}
                className="nav-links"
              >
                <span>Books</span>
              </div>
              <div
                onClick={() => {
                  navigate(PREFIX + "/poetry");
                  onClose();
                }}
                className="nav-links"
              >
                <span>Poetry</span>
              </div>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default MobileDropdown;
