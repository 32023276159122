import { motion } from "framer-motion";
// import useCustomMediaQuery from "../../hooks/custom_media_query";
import "../../css/books/searchBar.css";

const SearchBar = ({ onChange }) => {

  return (
    <>
      <div className="search-bar-container">
        <div className="search-bar-input-container">
          <motion.div className="hero-container" whileHover={{ scale: 1.1 }}>
            <span className="search-icon material-symbols-outlined">
              search
            </span>
            <input
              className="search-input"
              autoFocus={true}
              autoComplete="true"
              onChange={onChange}
              placeholder="Search Keyword...."
            ></input>
          </motion.div>
        </div>
        <div></div>
      </div>
    </>
  );
};

export default SearchBar;
