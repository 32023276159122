// import SearchBar from "./searchBar";
import { useEffect, useState } from "react";
import "../../css/books/books.css";
import { CircularProgress } from "@mui/joy";
import BooksContainer from "./books-container";
import SubmitBookDialog from "./submit-book/submit-book-dialog";
// import EpubViewer from "./epub_viewer";
import { useSelector, useDispatch } from "react-redux";
import { fetchBooksList } from "../../redux/book/books/actions";
import SearchBar from "./searchBar";

const Books = () => {
  const [isDialogOpened, setIsIsDialogOpened] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const books = useSelector((data) => data.books);
  const dispatch = useDispatch();

  const handleTouch = () => {
    // setIsTouched((prevVal) => !prevVal);
  };

  const handleDialogToggle = () => {
    setIsIsDialogOpened((prevVal) => !prevVal);
  };

  useEffect(() => {
    if (!books || !books.data || !books.data.length) dispatch(fetchBooksList());
  }, []);

  const filteredBooks = books.data.filter(
    (book) =>
      book.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      book.writerName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <SearchBar onChange={(value) => setSearchTerm(value.target.value)} />
      <div className="books-heading">
        <h1>Books</h1>
        <div className="quote-container">
          <span className="quote">
            &ldquo; One glance at a book and you hear the voice of another
            person, perhaps someone dead for 1,000 years. To read is to voyage
            through time.&rdquo;
          </span>
          <span className="quote-author">– Carl Sagan</span>
        </div>
      </div>
      <div className="books-content">
        {books.loading ? (
          <>
            <div>
              <CircularProgress variant="soft" />
            </div>
          </>
        ) : (
          filteredBooks.map((book, index) => (
            <BooksContainer key={index} handleTouch={handleTouch} book={book} />
          ))
        )}
      </div>
      <div className="submit-book" onClick={handleDialogToggle}>
        <span className="material-symbols-outlined">add_notes</span>
      </div>
      <SubmitBookDialog
        isOpen={isDialogOpened}
        onClose={handleDialogToggle}
      ></SubmitBookDialog>
    </>
  );
};

export default Books;
