import { useEffect } from "react";
import Navbar from "./components/navbar";
import "./css/App.css";
import { useDispatch } from "react-redux";
import { fetchProjects } from "./redux/actions";
import { fetchEducation } from "./redux/education/actions";
import { fetchAwards } from "./redux/awards/actions";
import { fetchExperience } from "./redux/experience/actions";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/home";
import Books from "./components/Books/books";
import { PlaybackProvider } from "./components/Books/music/playback_context";
import BookDetails from "./components/Books/Details/book-details";
import ReadBookComponent from "./components/Books/ReadBook/read-book";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PoetryMain from "./components/Poetry/poetry_main";
import { PREFIX } from "./components/Poetry/SlateEditor/utils/constants";

const App = () => {
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProjects());
    dispatch(fetchEducation());
    dispatch(fetchAwards());
    dispatch(fetchExperience());
  }, []);
  return (
    <>
      <PlaybackProvider>
        <Router>
          <Navbar />
          <Routes>
            <Route path={PREFIX + "/books"} element={<Books />}></Route>
            <Route
              path={PREFIX + "/book-details/:id"}
              element={<BookDetails />}
            ></Route>
            <Route
              path={PREFIX + "/read-book/:id"}
              element={<ReadBookComponent />}
            ></Route>
            <Route path={PREFIX + "/poetry"} element={<PoetryMain />}></Route>
            <Route
              path={PREFIX + "/poetry/:id/:docid"}
              element={<PoetryMain />}
            ></Route>
            <Route path={"/"} element={<Home />}></Route>

            <Route path="*" element={<Home />}></Route>
          </Routes>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Router>
      </PlaybackProvider>
    </>
  );
};

export default App;
